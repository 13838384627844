import Cookies from 'js-cookie';

export const baseURL = Cookies.get('REGIONAL_BACKEND_URL') || (process.env.BACKEND_URL as string);
export const env = process.env.NODE_ENV || 'dev';
export const PRIVACY_POLICY = process.env.CSP_SIEMENS_PRIVACY_POLICY;
export const SECURITY = process.env.CSP_SIEMENS_SECURITY;
export const TRUST_CENTER = process.env.CSP_SIEMENS_TRUST_CENTER;
export const PEP_TRUST_CENTER = process.env.PEP_SIEMENS_TRUST_CENTER;
export const SIEMENS_ANALYTICS_USE_PRODUCTION = process.env.SIEMENS_ANALYTICS_USE_PRODUCTION;
export const WALK_ME_ENV_TYPE = process.env.WALK_ME_ENV_TYPE;
export const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID as string;
export const SUBSCRIPTION_MANAGER = process.env.CSP_SUBSCRIPTION_MANAGER_URL;
export const DOCUMENTATION_URL = process.env.CSP_DOCUMENTATION_URL;
export const GTM_ID = process.env.GTM_ID;
export const GTM_AUTH = process.env.GTM_AUTH;
export const GTM_PREVIEW = process.env.GTM_PREVIEW;
export const IS_CLOUD_ENVIRONMENT = process.env.IS_CLOUD_ENVIRONMENT;
export const ANALYTICS_PRODUCT_KEY = process.env.ANALYTICS_PRODUCT_KEY as string;
export const TERMS_OF_USE = process.env.CSP_TERMS_OF_USE;
export const EDIF_PROTOCOL = process.env.EDIF_PROTOCOL;
export const CELUS_URL = process.env.CELUS_URL;
export const XCC_TERMS_OF_USE = process.env.XCC_TERMS_OF_USE;

export function getLocalstorageCleanupInterval() {
    const localstorageCleanupIntervalRaw = process.env.LOCALSTORAGE_CLEAR_INTERVAL;
    return !isNaN(Number(localstorageCleanupIntervalRaw))
        ? Number(localstorageCleanupIntervalRaw)
        : 90;
}

export default {
    baseURL,
    env,
    PRIVACY_POLICY,
    SECURITY,
    TRUST_CENTER,
    PEP_TRUST_CENTER,
    SIEMENS_ANALYTICS_USE_PRODUCTION,
    WALK_ME_ENV_TYPE,
    INTERCOM_APP_ID,
    SUBSCRIPTION_MANAGER,
    DOCUMENTATION_URL,
    GTM_ID,
    GTM_AUTH,
    GTM_PREVIEW,
    IS_CLOUD_ENVIRONMENT,
    ANALYTICS_PRODUCT_KEY,
    TERMS_OF_USE,
    EDIF_PROTOCOL,
    CELUS_URL,
    XCC_TERMS_OF_USE,
};
